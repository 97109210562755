import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "react-bootstrap";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false, // Avoid automatic style matching
  },
};

const getHtmlTextLength = (html) => {
  if (!html) return 0;
  try {
    const strip_tags = html.replace(/<(?!br\s*\/?)\[^>]+>/g, ""); // Remove tags except <br>
    const strip_enter = strip_tags.replace(/<br\s*\/?>/g, " "); // Replace <br> with space
    const strip_space = strip_enter.replace(/&nbsp;/gi, " "); // Remove &nbsp;
    return strip_space.length;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export default function ReportEditorQuill({
  text,
  onSave,
  maxLength,
  onCancle,
}) {
  const [editorHtml, setEditorHtml] = useState(text || "");
  const [length, setLength] = useState(getHtmlTextLength(text));
  const quillRef = useRef(null);

  // Update editor when `text` is received (async updates fix)
  useEffect(() => {
    if (text !== undefined && text !== editorHtml) {
      setEditorHtml(text);
      setLength(getHtmlTextLength(text));
    }
  }, [text]);

  // Handle content change & update text length
  const handleChange = (content) => {
    const newLength = getHtmlTextLength(content);
    if (newLength <= maxLength) {
      setEditorHtml(content);
      setLength(newLength);
    }
  };

  // Remove inline styles on paste
  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops.forEach((op) => {
          if (op.attributes) {
            // Preserve only allowed formats
            op.attributes = {
              bold: op.attributes.bold,
              italic: op.attributes.italic,
              underline: op.attributes.underline,
              list: op.attributes.list,
              link: op.attributes.link,
            };
          }
        });
        return delta;
      });
    }
  }, []);

  return (
    <>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={editorHtml}
        onChange={handleChange}
        modules={modules}
      />
      <div className="rightsideactions">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <div style={{ marginTop: "-5px" }}>
            <small>
              {length}/{maxLength}
            </small>
          </div>
          <div className="d-flex mt-3">
            <Button
              id="cancel"
              className="text-secondary500 bg-transparent border-0"
              onClick={onCancle}
            >
              Cancel
            </Button>
            <button
              className="btn btn-primary"
              onClick={() => onSave && onSave(editorHtml)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
