/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { HIDE_MODAL } from "../../../../store/actions";
// import ReportEditor from "../../../components/Editor/ReportEditor";
import ReportEditorQuill from "main/components/Editor/ReportEditorQuill";

export default function EditTextModal(props) {
  const { show, value, richText, textArea, ml, sethandleSaveState } = props;
  const [textValue, setTextValue] = useState(props.value);
  const dispatch = useDispatch();

  useEffect(() => {
    setTextValue(value ?? "");
  }, [value]);

  const handleSave = () => {
    props.setValue(value);
    sethandleSaveState(true);
    close();
  };

  const close = () => {
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <Modal
      show={show}
      onHide={richText ? () => {} : close}
      size={richText ? "xl" : "lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <h5 style={{ fontWeight: "bolder", margin: "0" }}>Edit</h5>
            </div>
            <FontAwesomeIcon
              style={{ cursor: "pointer", fontSize: "18px" }}
              icon={faTimes}
              onClick={close}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {richText ? (
          <div className="dialogbody">
            <ReportEditorQuill
              text={textValue}
              onSave={(val) => {
                props.setValue(val);
                // setTimeout(() => {
                //   props.handleSave();
                //   // console.log(report);
                // }, 2000);
                sethandleSaveState(true);
                // console.log(report,"ssssssssssssssssssssssss")
                // setTimeout(() => {

                // }, 1000);
                // saveReport(insp_id, { data: JSON.stringify(report) })
                //   .then(() => {
                //     showToast("Report saved successfully", true);
                //   })
                //   .catch((err) => {
                //     console.log(err);
                //     showToast(getErrorMsg(err) || "Something went wrong");
                //   });
                // .finally(() => setIsPdfLoading(false));
                close();
              }}
              onCancle={close}
              maxLength={ml}
            />
          </div>
        ) : (
          <div className="dialogbody">
            {!textArea ? (
              <input
                className="form-control"
                value={value}
                onChange={(e) => setTextValue(e.target.value)}
                maxLength={ml}
              />
            ) : (
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="12"
                  value={value}
                  onChange={(e) => setTextValue(e.target.value)}
                  maxLength={props.ml}
                ></textarea>
              </div>
            )}

            <span className="max-length-count">
              {value?.length ?? 0}/{ml}
            </span>
          </div>
        )}
      </Modal.Body>
      {!richText && (
        <Modal.Footer className="no-border">
          <Button
            id="cancel"
            className="text-secondary500 bg-transparent border-0"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            style={{ marginRight: 25 }}
            id="save-text"
            onClick={handleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
