/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import {
  getReportData,
  getReportDataV2,
  getReportPdf,
  refreshReport,
  saveReport,
} from "../../../services/IReportService";
import {
  RESET_REPORT_DATA,
  SET_REPORT_DATA,
  UPDATE_EXTRA_PAGE_REPORT_DATA,
  UPDATE_REPORT_DATA,
} from "../../../store/actions";
import Spinner from "../../components/Spinner";
import {
  deepCopyObject,
  formatReportData,
  getErrorMsg,
  getReportDataForApi,
  getReportKeys,
  isEmptyObject,
  setHeader,
  showToast,
} from "../../shared/helpers";
import { regexes } from "../../shared/regexes";
import AddPageButton from "./Components/AddPageButton";
import DocumentNavigator from "./Components/DocumentNavigator";
import EditTextModal from "./Components/EditTextModal";
import Background from "./Components/ReportPages/Background";
import ExtraPage from "./Components/ReportPages/ExtraPage";
import Intro from "./Components/ReportPages/Intro";
import ObservationComments from "./Components/ReportPages/ObservationsPages/ObservationComments";
import ObservationPhotos from "./Components/ReportPages/ObservationsPages/ObservationPhotos";
import ObservationTests from "./Components/ReportPages/ObservationsPages/ObservationTests";
import Protocol from "./Components/ReportPages/Protocol";
import ReportInspection from "./Components/ReportPages/ReportInspection";
import TemplatePage from "./Components/ReportPages/TemplatePage";
import TOC from "./Components/ReportPages/TOC";
import { HIDE_MODAL_CONFIRM } from "../../../store/actions";
import "./style.css";
import ReportTerms from "./Components/ReportPages/ReportTerms";

const ConfirmationModal = React.lazy(() =>
  import("../Settings/Users/ConfirmationModal")
);

const reportPages = {
  cover_page: Intro,
  toc: TOC,
  background: Background,
  inspection: ReportInspection,
  observations: "observations",
  specific_decontamination: (props) => (
    <TemplatePage templateKey={"specific_decontamination"} {...props} />
  ),
  generel_decontamination: (props) => (
    <TemplatePage templateKey={"generel_decontamination"} {...props} />
  ),
  sampling: (props) => <TemplatePage templateKey={"sampling"} {...props} />,
  laboratory: (props) => <TemplatePage templateKey={"laboratory"} {...props} />,
  interpreting: (props) => (
    <TemplatePage templateKey={"interpreting"} {...props} />
  ),
  health_effects: (props) => (
    <TemplatePage templateKey={"health_effects"} {...props} />
  ),
  reference: (props) => <TemplatePage templateKey={"reference"} {...props} />,
  limitation: (props) => <TemplatePage templateKey={"limitation"} {...props} />,
};

const fetchDataAndUpdate = async (dispatch, insp_id, section, controller) => {
  try {
    const inspWorkflow = await getReportDataV2(
      insp_id,
      section,
      controller.signal
    );
    // Check if the request was aborted
    if (controller.signal.aborted) {
      throw new Error("Request aborted");
    }

    console.log(inspWorkflow.data[section], "inspWorkflow.data[section]");
    return { [section]: inspWorkflow.data[section] };
  } catch (error) {
    // Handle errors as needed
    if (error.name === "AbortError") {
      console.log("Request aborted", error.message);
    } else {
      console.error(`Error fetching data for ${section}:`, error);
    }
    return { [section]: null }; // Return null or handle error data appropriately
  }
};
export default function InvestigationReport() {
  const [scrollToRow, setScrollToRow] = useState(0);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { insp_id } = useParams();
  const editModal = useSelector((state) => state.editModal);
  const observations = useSelector((state) => state.report?.observations?.data);
  const report = useSelector((state) => state.report);
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const [pages, setPages] = useState([]);
  const list = useRef(null);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 500,
    })
  );
  const setReportData = (res) => {
    const content = res?.data?.cover_page?.report?.terms?.content;
    if (content) {
      res.data.cover_page.report.terms.content = content.replace(
        regexes.styleAttribute,
        ""
      );
    }

    formatReportData(res.data);
    console.log(res.data);
    setHeader(dispatch, {
      title:
        "Inspection Report - " + res?.data?.cover_page?.property?.address ?? "",
    });
    dispatch({ type: SET_REPORT_DATA, payload: res.data });
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   getReportData(insp_id)
  //     .then((res) => {
  //       setReportData(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       showToast("Unable to load report data");
  //     })
  //     .finally(() => setIsLoading(false));
  //   console.log("useefect");
  // }, []);

  // useEffect(() => {
  //   const apiUrls = [
  //     "cover_page",
  //     "toc",
  //     "background",
  //     "inspection",
  //     "observations",
  //     "specific_decontamination",
  //     "generel_decontamination",
  //     "sampling",
  //     "laboratory",
  //     "interpreting",
  //     "health_effects",
  //     "reference",
  //     "limitation",
  //   ]; // Array of API URLs
  //   // const delayBetweenCalls = 6000; // 3 seconds delay

  //   // // const fetchData = async (url) => {
  //   // //   try {
  //   // //     const response = await fetch(url);
  //   // //     const data = await response.json();
  //   // //     console.log(data); // Do something with the fetched data
  //   // //   } catch (error) {
  //   // //     console.error("Error fetching data:", error);
  //   // //   }
  //   // // };
  //   //
  //   // const fetchApisSequentially = async (urls) => {
  //   //   if (urls.length === 0) return; // Base case: Stop recursion if no more URLs

  //   //   const url = urls.shift(); // Destructure the first URL and the rest

  //   //   let inspWorkflow = await getReportDataV2(insp_id, url); // Call API for the current URL
  //   //   dispatch({
  //   //     type: UPDATE_REPORT_DATA,
  //   //     payload: {
  //   //       [url]: inspWorkflow.data,
  //   //     },
  //   //   });

  //   //   setIsLoading(false);
  //   //   if (urls.length > 0) {
  //   //     // If there are remaining URLs, wait for the delay and call recursively
  //   //     await new Promise((resolve) => setTimeout(resolve, delayBetweenCalls));
  //   //     await fetchApisSequentially(urls); // Recursive call with remaining URLs
  //   //   }
  //   // };

  //   // fetchApisSequentially(apiUrls);
  //   const fetchDataAndUpdate = async (dispatch, insp_id, section) => {
  //     try {
  //       const inspWorkflow = await getReportDataV2(insp_id, section);
  //       dispatch({
  //         type: UPDATE_REPORT_DATA,
  //         payload: {
  //           [section]: inspWorkflow.data,
  //         },
  //       });
  //       setIsLoading(false);
  //     } catch (error) {
  //       // Handle errors as needed
  //       showToast(`Error fetching data for ${section}:`);

  //       console.error(`Error fetching data for ${section}:`, error);
  //     }
  //   };

  //   const name = async (params) => {
  //     setIsLoading(true);
  //     const sections = [
  //       "cover_page",
  //       "toc",
  //       "background",
  //       "inspection",
  //       "observations",
  //       "specific_decontamination",
  //       "generel_decontamination",
  //       "sampling",
  //       "laboratory",
  //       "interpreting",
  //       "health_effects",
  //       "reference",
  //       "limitation",
  //     ];

  //     for (const section of sections) {
  //       await fetchDataAndUpdate(dispatch, insp_id, section);
  //     }
  //   };

  //   name();
  // }, []); // Empty dependency array to run the effect only once
  const controllers = [];

  useEffect(() => {
    console.log(report, "reset");
    let abort = false;
    const fetchData = async () => {
      setIsLoading(true);
      const sections = [
        "cover_page",
        "toc",
        "background",
        "inspection",
        "observations",
        "specific_decontamination",
        "generel_decontamination",
        "sampling",
        "laboratory",
        "interpreting",
        "health_effects",
        "reference",
        "limitation",
        "extra_pages",
      ];

      let updated_report = {};
      try {
        // const { signal } = controller;

        for (const section of sections) {
          const controller = new AbortController();
          controllers.push(controller); // Store the controller in the array

          if (abort) break;
          const result = await fetchDataAndUpdate(
            dispatch,
            insp_id,
            section,
            controller
          );
          updated_report = {
            ...updated_report,
            ...result,
          };

          console.log(section, "my section");

          if (section === "cover_page") {
            const content = updated_report?.cover_page?.report?.terms?.content;
            if (content) {
              updated_report.cover_page.report.terms.content = content.replace(
                regexes.styleAttribute,
                ""
              );
            }
            setHeader(dispatch, {
              title:
                "Inspection Report - " +
                  updated_report?.cover_page?.property?.address ?? "",
            });
          }
          if (section === "toc") {
            updated_report = deepCopyObject(updated_report);

            formatReportData(updated_report);
            dispatch({
              type: UPDATE_REPORT_DATA,
              payload: updated_report,
            });
            setIsLoading(false);
          }
          if (section === "observations") {
            // TODO: the current logic delay due to the whole data parsing in the loop and then running the formatter that's need to be fixed
            updated_report = deepCopyObject(updated_report);

            formatReportData(updated_report);
            dispatch({
              type: UPDATE_REPORT_DATA,
              payload: updated_report,
            });
          } else {
            dispatch({
              type: UPDATE_REPORT_DATA,
              payload: result,
            });
          }
          // Object.assign();

          // setReportData(result);
          // setTimeout(() => {

          // }, 0);
        }

        console.log(report, "reset reoi");
      } catch (error) {
        // Handle errors as needed
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Cleanup function to abort any ongoing API calls if the component unmounts
    return () => {
      abort = true;
      console.log("[[[[[[[[[[[[[[[[[[[[[first]]]]]]]]]]]]]]]]]]]]]");
      controllers.forEach((controller) => {
        controller.abort();
        console.log(controller, "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
      });

      console.log(report, "reset out");
      // return () => {
      console.log("----------------------------reset");
      dispatch({
        type: RESET_REPORT_DATA,
        payload: {},
      });
      // };
    };
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    if (!isEmptyObject(report)) {
      console.time("Report loop");
      const tempPages = [];
      const reportKeys = getReportKeys(report);
      reportKeys?.forEach((key) => {
        if (key === "observations") {
          observations?.forEach((o, i) => {
            o?.observations?.forEach((obs, j) => {
              if (obs?.comments?.length > 0) {
                tempPages.push((props) => (
                  <ObservationComments
                    insp_id={insp_id}
                    sethandleSaveState={sethandleSaveState}
                    index={j}
                    roomIndex={i}
                    comments={obs.comments}
                    {...props}
                  />
                ));
              }
              if (obs?.images?.length > 0) {
                tempPages.push((props) => (
                  <ObservationPhotos
                    index={j}
                    roomIndex={i}
                    photos={obs?.images}
                    {...props}
                  />
                ));
              }
              if (obs?.tests?.length > 0) {
                tempPages.push((props) => (
                  <ObservationTests
                    index={j}
                    roomIndex={i}
                    tests={obs?.tests}
                    {...props}
                  />
                ));
              }
            });

            tempPages.push((props) => (
              <Protocol roomIndex={i} protocols={o.protocol_text} {...props} />
            ));
          });
        } else {
          tempPages.push(reportPages[key]);
        }
      });
      report?.extra_pages?.forEach((extra, i) => {
        tempPages.push((props) => (
          <ExtraPage {...props} data={extra} index={i} />
        ));
      });
      tempPages.push((props) => <AddPageButton {...props} />);
      setPages(tempPages);
      console.timeEnd("Report loop");
    }
  }, [report]);

  useEffect(() => {
    list?.current?.scrollToRow(scrollToRow);
    const id = setTimeout(() => {
      list?.current?.scrollToRow(scrollToRow);
    }, 250);

    return () => clearTimeout(id);
  }, [scrollToRow]);

  useEffect(() => {
    console.log(list.current?.Grid?._scrollingContainer);
  }, [list]);

  useEffect(() => {
    console.log(cache?.current?._rowHeightCache);
  }, [cache]);

  const handlePublish = () => {
    setIsPdfLoading(true);
    console.log(getReportDataForApi(report));
    getReportPdf(getReportDataForApi(report), insp_id)
      .then(() => {
        showToast(
          "Inspection Report will be available in Documents listing shortly.",
          true
        );
      })
      .catch((err) => {
        console.log(err);
        showToast("Error while generating pdf");
      })
      .finally(() => setIsPdfLoading(false));
  };

  const [handleSaveState, sethandleSaveState] = useState(false);
  useEffect(() => {
    if (handleSaveState) {
      handleSave();
      sethandleSaveState(false);
    }
  }, [report]);

  // console.log(handleSaveState, "-------------------")

  const handleSave = () => {
    setIsPdfLoading(true);
    saveReport(insp_id, { data: JSON.stringify(report) })
      .then(() => {
        showToast("Report saved successfully", true);
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Something went wrong");
      })
      .finally(() => setIsPdfLoading(false));
  };

  const handleRefresh = (setDisableBtns) => {
    setDisableBtns(true);
    setIsPdfLoading(true);
    refreshReport(insp_id)
      .then((res) => {
        showToast("Report refreshed successfully", true);
        setReportData(res);
        setShowRefreshModal(false);
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Something went wrong");
      })
      .finally(() => {
        setIsPdfLoading(false);
        setDisableBtns(false);
      });
  };
  console.log(
    report?.toc?.content?.length,
    "-----------------------------------------"
  );

  return (
    <div id="report" className="d-flex flex-row">
      <Spinner reactIf={isLoading} />
      {!isLoading &&
      !isEmptyObject(report) &&
      report?.toc?.content?.length > 0 ? (
        <>
          <div className="ls py-2 pr-0">
            <DocumentNavigator setScrollToRow={setScrollToRow} />
          </div>
          <div id="reportScroll" className="rs">
            <div className="report-action-container shadow">
              <Button
                className="report-save-btn dis-loading"
                onClick={handleSave}
                disabled={isPdfLoading}
              >
                Save
              </Button>
              <Button
                className="report-save-btn dis-loading"
                onClick={() => setShowRefreshModal(true)}
                disabled={isPdfLoading}
              >
                Refresh
              </Button>
              {/* <DownloadPDF report={report} /> */}
              <Button
                className="dis-loading"
                onClick={handlePublish}
                disabled={isPdfLoading}
              >
                Publish
              </Button>
            </div>
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={cache.current.rowHeight}
                  deferredMeasurementCache={cache.current}
                  rowCount={pages.length}
                  ref={list}
                  scrollToAlignment="start"
                  rowRenderer={({ key, index, style, parent }) => {
                    const Page = pages[index];
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <Page style={style} id={`page${index}`} />
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
          <EditTextModal
            {...editModal}
            sethandleSaveState={sethandleSaveState}
          />
        </>
      ) : null}
      <Suspense fallback={null}>
        <ConfirmationModal
          show={showRefreshModal}
          onHide={() => setShowRefreshModal(false)}
          msg={
            "Refreshing this document will erase ALL edited information and create a new draft. Are you sure you want to refresh this document?"
          }
          onDelete={handleRefresh}
          yesBtnText="Refresh"
          noBtnText="Cancel"
        />
        <ConfirmationModal
          show={editModal.showConfirm}
          onHide={() =>
            dispatch({
              type: HIDE_MODAL_CONFIRM,
            })
          }
          msg={"Are you sure you want to delete this photo?"}
          onDelete={editModal.onDelete}
          yesBtnText="Delete"
          noBtnText="Cancel"
          hideOnPress
        />
      </Suspense>
    </div>
  );
}
